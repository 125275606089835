import type { BrokerVO, PlatformSysUserVO, UserVO } from '@/pages/anchor/anchorManagement/type.ts';
import { api } from '@/utils/api.ts';
import { Button, Modal, Space, Tag, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { observable } from 'mobx';
import {
  type BaseData,
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  request,
  requireSource,
  transformKeys,
} from '../../../utils';

import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import { API } from './api.ts';
import { AnchorTagsModalStore } from './components/anchorTags/model.ts';
import { AuditModalStore } from './components/audit/model.ts';

interface Option {
  id?: string | number | null;
  name?: string;
  children?: Option[];
  isLeaf?: boolean;
}

export default class AnchorManagementModel {
  @observable auditModalStore: AuditModalStore = new AuditModalStore(this); //审核弹框store

  @observable anchorTagsModalStore: AnchorTagsModalStore = new AnchorTagsModalStore(this); //主播标签弹框store
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框
  //初始化查询条件数据
  init = async () => {
    //主播标签
    const categoriesList = await request<BaseData<Option[]>>({
      url: api.tag.getAllLibList,
      method: 'get',
      params: { categoryType: 5 },
    });
    this.mainStore.programme.filterItems.updateFilterItem([
      {
        field: 'categoryIdList',
        treeData: transformKeys(
          categoriesList.data.filter((e) => e.children.length > 0),
          {
            name: 'label',
            id: 'value',
          },
        ),
      },
    ]);

    const dictMaps = await request<BaseData<any>>({
      url: api.system.getDictMap,
      method: 'post',
      data: {
        dictTypeList: ['anchor_register_channel', 'service_name'],
      },
    });
    //注册来源
    const channel = dictMaps.data?.anchor_register_channel?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });
    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'channel', data: channel }]);

    //服务名称
    const serviceName = dictMaps.data?.service_name?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });
    this.mainStore.programme.filterItems.updateFilterItem([{ field: 'functionTypeList', data: serviceName }]);
    const brokerListResult = await request<PaginationData<BrokerVO>>({
      url: API.getBrokerList,
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    });
    //经纪人列表
    // const brokerList = brokerListResult.data?.list?.map((e) => {
    //   return { label: e.name, value: e.id.toString() };
    // });
    // this.mainStore.programme.filterItems.updateFilterItem([{ field: 'brokerId', data: brokerList }]);

    //媒介列表
    // const mediumListResult = await request<PaginationData<PlatformSysUserVO>>({
    //   url: API.getSysUserList,
    //   method: 'post',
    //   data: { pageNum: 1, pageSize: 1000, userRole: 6 },
    // });
    // const mediumList = mediumListResult.data?.list?.map((e) => {
    //   return { label: e.nickName, value: e.id.toString() };
    // });
    //   this.mainStore.programme.filterItems.updateFilterItem([{ field: 'mediumId', data: mediumList }]);
    /**
     * 省市
     */
    const cityResult = await request<BaseData<any>>({
      url: '/baseinfo/area/province/city/list',
      method: 'get',
      data: {},
    });
    const cityList = cityResult?.data || [];
    console.log(cityList);
    this.mainStore.programme.filterItems.updateFilterItem([
      {
        field: 'cityIdList',
        data: cityList,
      },
    ]);
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '主播名称',
        placeholder: '姓名',
      },
      {
        type: 'input',
        field: 'mobile',
        label: '手机号',
        placeholder: '手机号',
      },
      {
        type: 'input',
        field: 'inviteKeyWord',
        label: '注册推荐人',
        placeholder: '请输入',
      },
      // {
      //   type: 'select',
      //   field: 'brokerId',
      //   label: '经纪人',
      //   placeholder: '请选择',
      // },
      // {
      //   type: 'select',
      //   field: 'mediumId',
      //   label: '媒介',
      //   placeholder: '请选择',
      // },
      {
        type: 'select',
        field: 'functionTypeList',
        label: '服务名称',
        placeholder: '请选择',
        isParamList: true,
        mode: 'multiple',
      },
      {
        type: 'select',
        field: 'vitaComplete',
        label: '简历完善',
        placeholder: '请选择',
        data: [
          { label: '已完善', value: '1' },
          { label: '未完善', value: '0' },
        ],
      },
      {
        type: 'select',
        field: 'vitaAuditStatus',
        label: '审核处理',
        placeholder: '请选择',
        data: [
          { label: '待处理', value: '0' },
          { label: '已处理', value: '1' },
          { label: '无需处理', value: '99' },
        ],
      },
      {
        type: 'select',
        field: 'channel',
        label: '注册来源',
        placeholder: '请选择',
      },
      {
        type: 'treeSelect',
        field: 'categoryIdList',
        label: '主播标签',
        placeholder: '请选择',
        multiple: true,
        isParamList: true,
        treeCheckable: true,
        showCheckedStrategy: 'SHOW_CHILD',
        allowClear: true,
      },
      {
        type: 'cascader',
        field: 'cityIdList',
        label: '期望工作城市',
        placeholder: '请选择',
        isParamList: true,
        fieldNames: {
          label: 'name',
          value: 'id',
          children: 'childrenList',
        },
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '注册时间',
      },
      {
        type: 'select',
        field: 'isVirtual',
        label: '激活状态',
        value: '0',
        data: [
          { label: '已激活', value: '0' },
          { label: '未激活', value: '1' },
        ],
      },
    ],
  };

  constructor() {
    this.init();
    this.mainStore.grid.onQuery();
  }

  // 封禁
  private onHandleDisabled = (userId: string, accountStatus: number) => {
    const tips = accountStatus === 0 ? '封禁' : '解封';
    Modal.confirm({
      title: '提示',
      content: `确认${tips}该主播账号?`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: API.updateAccountStatus,
          method: 'post',
          data: {
            userId,
            accountStatus: accountStatus === 0 ? 1 : 0,
          },
        });
        this.mainStore.grid.onQuery();
        message.success(`${tips}成功`);
      },
    });
  };

  // 同步播圈
  private syncBoquanHandler = (userIdList: string[]) => {
    Modal.confirm({
      title: '提示',
      content: '是否确认同步播圈?',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await request({
          url: API.syncBoquan,
          method: 'post',
          data: {
            userIdList,
          },
        });
        message.success('同步播圈成功');
        this.mainStore.grid.onQuery();
      },
    });
  };

  public grid: IMainSubStructureModel<UserVO> = {
    buttons: [
      {
        text: '批量同步播圈',
        permissionId: '47',
        handleClick: () => {
          const selectedIds = this.mainStore.grid.gridModel.selectedIds;
          if (Array.from(selectedIds).length === 0) {
            message.warning('请选择需要同步播圈的主播');
            return;
          }
          const userIdList: string[] = Array.from(selectedIds) as any;
          this.syncBoquanHandler(userIdList);
        },
      },
      {
        text: '批量主播标签',
        permissionId: '48',
        handleClick: () => {
          const selectedRows = this.mainStore.grid.gridModel.selectRows;
          if (selectedRows.length === 0) {
            message.warning('请选择需要设置标签的主播');
            return;
          }
          this.anchorTagsModalStore.onShow(selectedRows);
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'opreations',
          name: '操作',
          width: 360,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            const { accountStatus, auditStatus } = row; //0正常 1封禁
            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <Button
                    onClick={() => {
                      //查看主播详情
                      window.top.RingPermission.openTab(`/pbb-pc-management/anchorDetail/${row.id}`, '67', '主播详情');
                    }}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                {auditStatus !== 1 && auditStatus !== 2 && (
                  <RenderByPermission permissionId={`${pageId}_14`}>
                    <Button
                      onClick={() => {
                        this.auditModalStore.onShow(row.id);
                      }}
                      type="link"
                    >
                      审核
                    </Button>
                  </RenderByPermission>
                )}
                <RenderByPermission permissionId={`${pageId}_44`}>
                  <Button
                    onClick={() => {
                      this.syncBoquanHandler([row.id]);
                    }}
                    type="link"
                  >
                    同步播圈
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_45`}>
                  <Button
                    onClick={() => {
                      this.anchorTagsModalStore.onShow([row]);
                    }}
                    type="link"
                  >
                    主播标签
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_46`}>
                  <Button
                    onClick={() => {
                      this.onHandleDisabled(row.id, accountStatus);
                    }}
                    type="link"
                  >
                    {accountStatus === 0 ? '封禁' : '解封'}
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '主播信息',
          formatter: ({ row }) => {
            const { name, nickName, avatar, serviceObjectList } = row;
            const isPay = serviceObjectList && serviceObjectList.length > 0;
            return (
              <div
                className={'flex flex-row items-center h-full'}
                onClick={() => {
                  //弹出主播信息资料
                  this.anchorInfoModalStore.onShow(row.id);
                }}
              >
                <img
                  src={avatar}
                  alt={''}
                  className={`w-13 h-13 rounded-2 ${isPay ? 'border-[#D48806] border-solid border-[1px]' : ''}`}
                />
                <div className={'flex flex-col ml-4.5'}>
                  {isPay && (
                    <img
                      src={requireSource('/common/payStatus.png')}
                      alt={''}
                      className={'w-12 h-4.5 mb-1'}
                    />
                  )}
                  <div className={'flex flex-row items-center h-5'}>
                    <div className={'line-clamp-1'}>{name || nickName}</div>
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'mobile',
          name: '手机号',
        },
        {
          key: 'serviceObjectList',
          name: '服务名称',
          formatter: ({ row }) => {
            const { serviceObjectList } = row;
            if (!serviceObjectList || serviceObjectList.length === 0) {
              return '免费服务';
            }
            return (
              <Space className="flex  flex-col  items-start justify-center h-full">
                {serviceObjectList.map((e, index) => {
                  return (
                    <p
                      key={`${e.serviceName}_${index}`}
                      className="leading-[normal]"
                    >
                      {e.serviceName}
                    </p>
                  );
                })}
              </Space>
            );
          },
        },
        {
          key: 'tagList',
          name: '主播标签',
          formatter: ({ row }) => {
            const { tagList } = row;
            if (!tagList) {
              return '';
            }
            return (
              <Tooltip title={tagList.map((item) => item.libName).join(', ')}>
                {tagList?.map((item) => {
                  return (
                    <Tag
                      className="text-primary text-12 px-2 bg-[#1D53D91A] border-[#3A6EF866] border-[1px]"
                      key={item.libId}
                    >
                      {item.libName}
                    </Tag>
                  );
                })}
              </Tooltip>
            );

            // return tagList?.map((item) => {
            //   return (
            //     <Tag
            //       className="text-primary text-12 px-2 bg-[#1D53D91A] border-[#3A6EF866] border-[1px]"
            //       key={item.libId}
            //     >
            //       {item.libName}
            //     </Tag>
            //   );
            // });
          },
        },
        {
          key: 'cityName',
          name: '期望工作城市',
          formatter: ({ row }) => {
            if (row?.userJobHuntVO?.cityName) {
              return row.userJobHuntVO.cityName;
            } else {
              return '-';
            }
          },
        },
        {
          key: 'auditStatus',
          name: '审核处理',
          formatter: ({ row }) => {
            const { auditStatus } = row;
            if (auditStatus === 0) {
              return <div className={'text-primary'}>待处理</div>;
            } else if (auditStatus === 99) {
              return <div className={'text-secondary'}>无需处理</div>;
            }
            return '已处理';
          },
        },
        {
          key: 'vitaComplete',
          name: '简历完善',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            if (userJobHuntVO?.vitaComplete === 1) {
              return (
                <div className={'flex flex-row items-center'}>
                  <img
                    src={requireSource('/common/complete.png')}
                    className={'w-3 h-3'}
                    alt={''}
                  />
                  <div className={'text-[#52C41A] ml-1'}>已完善</div>
                </div>
              );
            }
            return (
              <div className={'flex flex-row items-center'}>
                <img
                  src={requireSource('/common/uncomplete.png')}
                  className={'w-3 h-3'}
                  alt={''}
                />
                <div className={'text-[#F5222D] ml-1'}>未完善</div>
              </div>
            );
          },
        },
        {
          key: 'userJobHuntVO',
          name: '简历更新时间',
          formatter: ({ row }) => {
            const { userJobHuntVO } = row;
            return userJobHuntVO?.vitaUpdateTime
              ? dayjs(userJobHuntVO?.vitaUpdateTime).format('YYYY-MM-DD HH:mm:ss')
              : '';
          },
        },
        // {
        //   key: 'brokerName',
        //   name: '经纪人',
        // },
        // {
        //   key: 'mediumName',
        //   name: '媒介',
        // },
        {
          key: 'accountStatus',
          name: '账号状态',
          formatter: ({ row }) => {
            const { accountStatus } = row;
            if (accountStatus === 0) {
              return (
                <div className={'flex flex-row items-center'}>
                  <div className={'w-1.5 h-1.5 rounded-1.5 bg-[#52C41A]'} />
                  <div className={'ml-1'}>正常</div>
                </div>
              );
            }
            return (
              <div className={'flex flex-row items-center'}>
                <div className={'w-1.5 h-1.5 rounded-1.5 bg-[#FF4D4F]'} />
                <div className={'ml-1'}>已封禁</div>
              </div>
            );
          },
        },
        {
          key: 'channelName',
          name: '注册来源',
        },
        {
          key: 'inviteName',
          name: '注册推荐人',
        },
        {
          key: 'isVirtual',
          name: '激活状态',
          formatter: ({ row }) => {
            const { isVirtual } = row;
            return isVirtual === 1 ? '未激活' : '已激活';
          },
        },
        {
          key: 'createTime',
          name: '注册时间',
          formatter: ({ row }) => {
            const { createTime } = row;
            return createTime ? dayjs(createTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      rowHeight: 68,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
      pageSize: 20,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        if (postParams.createTime) {
          postParams.startTime = postParams.createTime.split(',')[0];
          postParams.endTime = postParams.createTime.split(',')[1];
          postParams.createTime = undefined;
        }
        if (postParams.cityIdList?.length > 0) {
          postParams.cityIdList = [postParams.cityIdList?.pop()];
        }
        return request<PaginationData<UserVO>>({
          url: API.queryAnchorList,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
