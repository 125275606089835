import type { SysPositionVO, SysTagCategoryVO, SysTagLibVO } from '@/pages/positionManagement/interface.ts';
import {
  MainSubStructureModel,
  NormalProgramme,
  type PaginationData,
  RenderByPermission,
  getWxUrl,
  request,
  transformKeys,
} from '@/utils';
import { Modal, Popover, Space, Tag, message } from 'antd';
import { action, observable, runInAction } from 'mobx';
import AddModel from './components/add/model.tsx';
import {
  acceptNewbieDict,
  hasSocialSecurityDict,
  hasTryPostDict,
  hasTryUseDict,
  isKeepOfficeDict,
  jobTypeDict,
  requireSexDict,
  statusDict,
} from './enumeration.ts';
import InformationPopupModel from './informationPopupModel.tsx';
import RecommendedPositionModel from './recommendedPositionModel.tsx';
export default class PositionManagementModel {
  constructor() {
    this.__init__();
  }
  public informationPopupModel = new InformationPopupModel(this);
  public recommendedPositionModel = new RecommendedPositionModel(this);
  public addModel = new AddModel(this);
  public normalProgramme: NormalProgramme;
  public mainSubStructureModel: MainSubStructureModel;
  @observable public auditPositionOpen = false;
  public categoryIdList: string[] = [];
  @observable public categoryList = {}; //自定义标签原数组
  public temporaryId: string | number; // 弹窗临时使用的id
  @action
  public __init__() {
    this.initNormalProgramme();
    this.initMainSubStructureModel();
    this.getCategory();
    this.getLiveTypeList();
    setTimeout(() => {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('positionId') && searchParams.get('isShowDetail') === 'true') {
        this.addModel.onOpen(2, searchParams.get('positionId'));
      }
    }, 500);
  }
  @action
  public getLiveTypeList = () => {
    request<PaginationData<any>>({
      url: '/pbb/platform/anon/system/liveType/list',
      method: 'post',
      data: { pageNum: 1, pageSize: 1000 },
    }).then((res) => {
      this.normalProgramme.filterItems.updateFilterItem([
        {
          field: 'liveTypeId',
          data: transformKeys(res.data, {
            name: 'label',
            id: 'value',
            childrenList: 'children',
          }),
        },
      ]);
    });
  };
  @action
  public getCategory = async () => {
    const req = await request<PaginationData<SysTagCategoryVO>>({
      method: 'POST',
      url: '/pbb/platform/sys/tag/category/list/page',
      data: {
        pageNum: 1,
        pageSize: 9999,
        showState: true,
        categoryType: 3,
      },
    });
    const categoryIdList: string[] = [];
    const categoryList = {};
    (req?.data?.list || [])?.forEach((item) => {
      categoryIdList.push(item.id);
      categoryList[item.id] = { categoryName: item.categoryName };
      this.getTagList(item.id);
      this.normalProgramme.filterItems.addItem([
        {
          type: 'select',
          mode: 'multiple',
          field: item.id,
          label: item.categoryName,
        },
      ]);
    });
    this.categoryList = categoryList || {};
    this.categoryIdList = categoryIdList || [];
  };
  @action
  public getTagList = async (id: string) => {
    const req = await request<PaginationData<SysTagLibVO>>({
      method: 'POST',
      url: '/pbb/platform/sys/tag/lib/list/page',
      data: {
        categoryIdList: [id],
        pageNum: 1,
        pageSize: 9999,
        categoryType: 3,
        showState: true,
      },
    });
    const data = (req?.data?.list || [])?.map((item) => {
      return {
        label: item.libName,
        value: item.id,
      };
    });
    const dict: any = { [id]: data };
    if (this.categoryList[id]) {
      this.categoryList[id] = { ...this.categoryList[id], list: data };
    }
    this.normalProgramme.filterItems.addDict(dict);
  };
  @action
  public onOrOffPosition = ({ id, status, callback }: { id: string; status: 1 | 2; callback?: () => void }) => {
    let title = '';
    if (status === 1) {
      title = '是否确认下线该职位，下线后将不会有主播报名';
    }
    if (status === 2) {
      title = '是否确认上线该职位';
    }
    Modal.confirm({
      type: 'warning',
      autoFocusButton: null,
      title,
      onOk: async () => {
        return await request({
          method: 'POST',
          url: '/pbb/platform/sys/position/onOrOffPositionById',
          data: {
            id,
            status,
          },
        }).then((req: any) => {
          message.success(req?.data || '操作成功');
          if (callback) {
            callback();
          }
          this.mainSubStructureModel.onQuery();
        });
      },
      onCancel: () => {},
    });
  };

  /**
   * 删除职位
   * @param id
   * @param callback
   */
  @action
  public onDelete = (id: string | number, callback?: () => void) => {
    Modal.confirm({
      type: 'warning',
      autoFocusButton: null,
      title: '确认删除该职位？',
      onOk: async () => {
        return await request({
          method: 'POST',
          url: '/pbb/platform/sys/position/deletePosition',
          data: {
            id,
          },
        }).then(() => {
          message.success('操作成功');
          if (callback) {
            callback();
          }
          this.mainSubStructureModel.onQuery();
        });
      },
      onCancel: () => {},
    });
  };
  /**
   * 审核职位
   */
  @action
  public onAuditPosition = async ({
    auditReason,
    status,
  }: {
    auditReason?: string;
    status: number;
  }): Promise<boolean> => {
    return await request({
      method: 'POST',
      url: '/pbb/platform/sys/position/auditPosition',
      data: {
        positionIds: [this.temporaryId],
        auditReason,
        status,
      },
    })
      .then(() => {
        message.success('操作成功');
        if (this.auditPositionCallback) {
          this.auditPositionCallback();
        }
        runInAction(() => {
          this.auditPositionOpen = false;
          this.auditPositionCallback = undefined;
        });

        this.mainSubStructureModel.onQuery();
        return true;
      })
      .catch(() => {
        return false;
      });
  };
  public auditPositionCallback: () => void;
  @action
  public setAuditPositionOpen = () => {
    this.auditPositionOpen = !this.auditPositionOpen;
    if (!this.auditPositionOpen) {
      this.auditPositionCallback = undefined;
    }
  };

  @action
  public initNormalProgramme = () => {
    this.normalProgramme = new NormalProgramme({
      filterItems: [
        {
          type: 'select',
          field: 'jobType',
          label: '职位类型',
          data: [
            {
              label: '全职',
              value: '1',
            },
            {
              label: '长期兼职',
              value: '2',
            },
            {
              label: '临时兼职',
              value: '3',
            },
          ],
        },
        {
          type: 'input',
          field: 'name',
          label: '职位名称',
        },
        {
          type: 'input',
          field: 'companyName',
          label: '企业名称',
        },
        {
          type: 'cascader',
          field: 'liveTypeId',
          label: '直播类目',
        },
        {
          data: [
            {
              label: '不限',
              value: '0',
            },
            {
              label: '男',
              value: '1',
            },
            {
              label: '女',
              value: '2',
            },
          ],
          field: 'requireSex',
          label: '性别',
          type: 'select',
        },
        {
          type: 'select',
          field: 'isKeepOffice',
          label: '是否需要坐班',
          data: [
            {
              label: '是',
              value: '1',
            },
            {
              label: '否',
              value: '0',
            },
          ],
        },
        {
          type: 'select',
          field: 'hasSocialSecurity',
          label: '是否购买社保',
          data: [
            {
              label: '否',
              value: '0',
            },
            {
              label: '入职购买',
              value: '1',
            },
            {
              label: '转正购买',
              value: '2',
            },
          ],
        },
        {
          type: 'select',
          field: 'acceptNewbie',
          label: '是否接受小白',
          data: [
            {
              label: '是',
              value: '1',
            },
            {
              label: '否',
              value: '0',
            },
          ],
        },
        {
          type: 'select',
          field: 'hasTryUse',
          label: '是否有试用期',
          data: [
            {
              label: '是',
              value: '1',
            },
            {
              label: '否',
              value: '0',
            },
          ],
        },
        {
          type: 'inputNumberGroup',
          field: 'salary',
          label: '薪资',
        },
        {
          type: 'select',
          field: 'hasTryPost',
          label: '是否有试岗期',
          data: [
            {
              label: '是',
              value: '1',
            },
            {
              label: '否',
              value: '0',
            },
          ],
        },
        {
          type: 'select',
          field: 'status',
          label: '状态',
          data: [
            {
              label: '待上线',
              value: '2',
            },
            {
              label: '已上线',
              value: '1',
            },
            {
              label: '待审核',
              value: '3',
            },
            {
              label: '审核失败',
              value: '4',
            },
          ],
        },
      ],

      handleSearch: () => this.mainSubStructureModel.onQuery(),
    });
  };
  @action
  public initMainSubStructureModel = () => {
    this.mainSubStructureModel = new MainSubStructureModel({
      buttons: [
        {
          text: '新增',
          permissionId: '2',
          handleClick: () => {
            this.addModel.onOpen(1);
          },
        },
        {
          text: '批量推荐',
          permissionId: '71',
          handleClick: () => {
            const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
            if (!ids.length) {
              message.warning('请选择需要推荐的职位！');
              return;
            }
            if (this.mainSubStructureModel.gridModel.selectRows.every((item) => item.status === 1)) {
              this.recommendedPositionModel.onOpen(ids as string[]);
              return;
            }
            message.warning('请选择已上线的职位进行推荐！');
          },
        },
      ],
      pageId: new URLSearchParams(window.location.search)?.get('pageId'),
      grid: {
        columns: [
          {
            key: 'operate',
            name: '操作',
            minWidth: 260,
            formatter: ({ row }) => {
              const pageId = new URLSearchParams(window.location.search)?.get('pageId');
              const { status } = row;
              return (
                <Space>
                  <RenderByPermission permissionId={`${pageId}_21`}>
                    <a
                      onClick={async () => {
                        this.addModel.onOpen(2, row.id);
                      }}
                    >
                      详情
                    </a>
                  </RenderByPermission>
                  {[2].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_12`}>
                      <a
                        onClick={() => {
                          this.onOrOffPosition({ id: row.id, status: 2 });
                        }}
                      >
                        上线
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_71`}>
                      <Space>
                        <a
                          onClick={() => {
                            this.recommendedPositionModel.onOpen([row.id] as string[]);
                          }}
                        >
                          推荐
                        </a>
                        <a
                          type="link"
                          onClick={() => {
                            this.recommendedPositionModel.downloadPositionCard(row);
                          }}
                        >
                          下载职位卡片
                        </a>
                      </Space>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_71`}>
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          getWxUrl({
                            channel: 1,
                            positionId: row.id,
                          }).then((url) => {
                            copyToClipboard(url);
                            message.success('复制分享职位链接成功');
                          });
                        }}
                      >
                        分享
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[1].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_12`}>
                      <a
                        onClick={() => {
                          this.onOrOffPosition({ id: row.id, status: 1 });
                        }}
                      >
                        下线
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[3, 4].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_14`}>
                      <a
                        onClick={() => {
                          runInAction(() => {
                            this.temporaryId = row.id;
                            this.auditPositionOpen = true;
                          });
                        }}
                      >
                        人工审核
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                  {[2, 3, 4].includes(status) ? (
                    <RenderByPermission permissionId={`${pageId}_4`}>
                      <a
                        onClick={() => {
                          this.onDelete(row.id);
                        }}
                      >
                        删除
                      </a>
                    </RenderByPermission>
                  ) : undefined}
                </Space>
              );
            },
          },
          {
            key: 'status',
            name: '状态',
            formatter: ({ row }) => {
              return statusDict[row?.status] || '';
            },
          },
          {
            key: 'companyAddress',
            name: '城市',
            formatter: ({ row }) => {
              const { provinceName, cityName, countyName } = row.companyAddress;
              return `${provinceName || ''}${cityName || ''}${countyName || ''}`;
            },
          },
          {
            key: 'jobType',
            name: '职位类型',
            formatter: ({ row }) => {
              return jobTypeDict[row?.jobType] || '';
            },
          },
          {
            key: 'name',
            name: '职位名称',
            minWidth: 166,
          },
          {
            key: 'liveTypeName',
            name: '直播类目',
            minWidth: 166,
          },
          {
            key: 'sysTagVOList',
            name: '职位标签',
            formatter: ({ row }) => {
              const dom = (row?.sysTagVOList || [])?.map((item, index) => {
                return <Tag key={`${index?.toString()}libName`}>{item.libName}</Tag>;
              });
              return (
                <Popover
                  placement="top"
                  title={'职位标签'}
                  content={<Space>{dom}</Space>}
                >
                  <Space>{dom}</Space>
                </Popover>
              );
            },
          },
          {
            key: 'brokerRecommend',
            name: '推荐理由',
            formatter: ({ row }) => {
              return <div className={'truncate w-full'}>{row?.brokerRecommend || '-'}</div>;
            },
          },
          {
            key: 'salary',
            name: '薪资',
            minWidth: 188,
            formatter: ({ row }) => {
              if (row?.jobType?.toString() === '1' && row.salaryStart && row.salaryEnd) {
                return (
                  <div>
                    {row.salaryStart}-{row.salaryEnd}元*{row.salaryMonth}月
                  </div>
                );
              }
              if (['2', '3'].includes(row.jobType?.toString()) && row.salaryStart && row.salaryEnd) {
                return (
                  <div>
                    {row.salaryStart}-{row.salaryEnd}元/时
                  </div>
                );
              }
              return '';
            },
          },
          {
            key: 'recommendCount',
            name: '推荐人数',
            formatter: ({ row }) => {
              if (row.recommendCount) {
                return (
                  <a
                    onClick={() => {
                      this.informationPopupModel.onOpen(1, row.name, row.recommendCount, row.id, row.companyName);
                    }}
                  >
                    {row.recommendCount}人
                  </a>
                );
              }
              return '0人';
            },
          },
          {
            key: 'signUpCount',
            name: '报名人数',
            sortable: true,
            formatter: ({ row }) => {
              if (row.signUpCount) {
                return (
                  <a
                    onClick={() => {
                      this.informationPopupModel.onOpen(2, row.name, row.signUpCount, row.id, row.companyName);
                    }}
                  >
                    {row.signUpCount}人
                  </a>
                );
              }
              return '0人';
            },
          },
          {
            key: 'inviteCount',
            name: '邀约人数',
            sortable: true,
            formatter: ({ row }) => {
              if (row.inviteCount) {
                return (
                  <a
                    onClick={() => {
                      this.informationPopupModel.onOpen(3, row.name, row.inviteCount, row.id, row.companyName);
                    }}
                  >
                    {row.inviteCount}人
                  </a>
                );
              }
              return '0人';
            },
          },
          {
            key: 'companyName',
            name: '企业名称',
          },
          {
            key: 'info',
            name: '工作地址',
            minWidth: 300,
            formatter: ({ row }) => {
              const { info } = row.companyAddress;
              return `${info || ''}`;
            },
          },
          {
            key: 'requireSex',
            name: '主播性别',
            formatter: ({ row }) => {
              return requireSexDict[row?.requireSex] || '';
            },
          },
          {
            key: 'acceptNewbie',
            name: '是否接受小白',
            formatter: ({ row }) => {
              return acceptNewbieDict[row?.acceptNewbie] || '';
            },
          },
          {
            key: 'isKeepOffice',
            name: '是否需要坐班',
            formatter: ({ row }) => {
              return isKeepOfficeDict[row?.isKeepOffice] || '';
            },
          },
          {
            key: 'hasSocialSecurity',
            name: '是否购买社保',
            formatter: ({ row }) => {
              return hasSocialSecurityDict[row?.hasSocialSecurity] || '';
            },
          },
          {
            key: 'hasTryPost',
            name: '是否有试岗期',
            formatter: ({ row }) => {
              return hasTryPostDict[row?.hasTryPost] || '';
            },
          },
          {
            key: 'hasTryUse',
            name: '是否有试用期',
            formatter: ({ row }) => {
              return hasTryUseDict[row?.hasTryUse] || '';
            },
          },
        ].map((v) => ({
          resizable: true,
          sortable: false,
          ...v,
        })),
        rows: [],
        primaryKeyField: 'id',
        sortByLocal: false,
        showCheckBox: true,
        showEmpty: true,
      },
      hiddenSubTable: true,
      api: {
        onQuery: (params) => {
          const postParams = {
            ...params,
            filterParams: undefined,
          };
          const filterParams = this.normalProgramme.filterItems.params;
          if (filterParams?.salary) {
            const [salaryStart, salaryEnd] = filterParams.salary.split(',');
            filterParams.startTime = salaryStart;
            filterParams.endTime = salaryEnd;
          }
          let tagIds = [];
          Object.keys(filterParams).forEach((key) => {
            if (this.categoryIdList.includes(key)) {
              tagIds = [...tagIds, ...(filterParams[key] ? filterParams[key]?.split(',') || [] : [])];
              delete filterParams[key];
            }
          });
          if (filterParams.liveTypeId) {
            filterParams.liveTypeId = filterParams.liveTypeId?.split(',')?.[1];
          }

          return request<PaginationData<Partial<SysPositionVO>>>({
            url: '/pbb/platform/sys/position/getPositionPageList',
            method: 'POST',
            data: {
              ...postParams,
              ...filterParams,
              tagIds: tagIds,
            },
          });
        },
      },
    });
    this.mainSubStructureModel.gridModel.onQuery();
  };
}
const copyToClipboard = async (text: string) => {
  // 尝试使用新的 Clipboard API
  if (navigator.clipboard && window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Text copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text using Clipboard API: ', err);
    }
    return;
  }

  // 回退到使用 document.execCommand 方法
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      console.log('Text copied to clipboard');
    } else {
      throw new Error('Failed to copy text using execCommand');
    }
  } catch (err) {
    console.error('Failed to copy text using execCommand: ', err);
  } finally {
    document.body.removeChild(textarea);
  }
};
